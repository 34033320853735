import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { Button, Card, Elevation, Spinner } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css"
import axios from 'axios'
import { RouteComponentProps } from 'react-router-dom'

enum QueryState {
  QUERYING,
  WAITING,
  ERROR,
  DONE
}
interface MatchParams {
  docType: string;
  sfid: string;
}
interface CreateDocResponse {
  status: string,
  sf_url?: string,
  api_url?: string,
  display_name?: string,
  message?: string
}
interface Props extends RouteComponentProps<MatchParams> { }
const DocInfo = (resp: CreateDocResponse) => {
  return (
    <div style={{ wordBreak: "break-all", wordWrap: "break-word" }}>
      <p>正常に書類が作製出来ました</p>
      <p>Status: {resp.status}</p>
      <p>Document: {resp.display_name}</p>
      <p>MakeLeaps URL: <a href={resp.sf_url}>{resp.sf_url}</a></p>
      <p>API URL: {resp.api_url}</p>
    </div>
  )
}
const ErrorInfo = (resp: CreateDocResponse) => (
  <div>
    <p>エラーが発生しました</p>
    <p>{resp.message}</p>
  </div>
)
const CreateDocView: React.FC<Props> = ({ match }) => {
  // asynchronous axios
  const [queryState, setQueryState] = useState<QueryState>(QueryState.QUERYING);
  const [data, setData] = useState({});
  const [response, setResponse] = useState<CreateDocResponse>({ status: "unknown" });
  useEffect(() => {
    const fetchData = async () => {
      setQueryState(QueryState.WAITING);
      const result = await axios('/api/' + match.params.docType + '_doc/' + match.params.sfid);
      setQueryState(QueryState.DONE);
      setData(result.data);
      setResponse(result.data);
    }
    fetchData();
  }, [])
  const quoteObjectUrl = "https://caddi.lightning.force.com/lightning/r/quote__c/" + match.params.sfid + "/view";
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      width: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '30px',
      border: 0
    }}>
      <div style={{ marginTop: '30px' }}>
        <Card interactive={false} elevation={Elevation.TWO}>
          <div style={{ marginBottom: '20px', textAlign: 'center' }}>
            <p>書類種類: {match.params.docType}, Salesforce ID: {match.params.sfid}</p>
            <p>見積りオブジェクト: <a href={quoteObjectUrl}>こちら</a></p>
            <p>書類を発行しております。少々お待ち下さい。</p>
            {queryState == QueryState.QUERYING && <p>各サービスと通信中です。。。</p>}
          </div>
          {queryState == QueryState.WAITING && <Spinner size={Spinner.SIZE_LARGE}></Spinner>}
          <div>
            {response.status == "error" && ErrorInfo(response)}
            {response.status == "success" && DocInfo(response)}
          </div>
        </Card>
      </div>
      <div style={{ marginTop: '30px' }}>
        <Card interactive={false} elevation={Elevation.TWO}>
          <p>以下デバッグデータなので無視して下さい</p>
          <div style={{ wordBreak: "break-all", wordWrap: "break-word" }}>
            {JSON.stringify(data)}
          </div>
        </Card>
      </div>
    </div >
  );
}

export default CreateDocView;