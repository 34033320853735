import React from 'react';
import styled from 'styled-components/macro';
import { createGlobalStyle, css } from 'styled-components/macro';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CreateDocView from './CreateDocView'
import TopView from './TopView'
import { createBrowserHistory } from 'history';
import DefaultApp from './DefaultApp'
export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});

const Button = styled.button({
  display: 'flex',

})

const cssHelper = css({
  backgroundColor: 'black'
})

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={TopView} />
        <Route exact path='/default' component={DefaultApp} />
        <Route exact path='/createDoc/:docType/:sfid' component={CreateDocView} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
